import React from 'react'
import { Col, Row } from 'reactstrap'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Paper from '@material-ui/core/Paper/Paper'
import ios from '../../../assets/app-store-badge.svg'
import google from '../../../assets/google-play-badge.png'
import './ViewOurApp.css'

library.add(fab, far, fal, fad, fas)

export default function ViewOurApp(props: any) {
  const { config, manifest, anchorID } = props
  return (
    <Row id={anchorID} className={config?.additionalTailwindStyles}>
      <Col className={'ViewOurAppColOne align-items-center'} xs={10}>
        <Paper className="ViewOurAppPaperOne">
          <img
            className="ViewOurAppImgOne"
            src={
              manifest['stylesheet'] && manifest['stylesheet'].images
                ? manifest['stylesheet'].images[config.icon]['url']
                : ''
            }
            alt={
              manifest['stylesheet'] && manifest['stylesheet'].images
                ? manifest['stylesheet'].images[config.icon]['altText']
                : ''
            }
            height={100}
            width={100}
          />
          <h1 className="ViewOurAppH1 text-2xl font-semibold my-4">
            {config.appTitle}
          </h1>
          <p dangerouslySetInnerHTML={{ __html: config.appDescription }} />
          <Row>
            <Col className="ViewOurAppColTwo" xs={10}>
              <a
                href={config.iosLink}
                rel="noopener noreferrer"
                target={'_blank'}
                className="ViewOurAppAnchor"
              >
                <img className="ViewOurAppBadgeStyle" alt="ios" src={ios} />
              </a>
              <a
                href={config.androidLink}
                rel="noopener noreferrer"
                target={'_blank'}
                className="ViewOurAppAnchor"
              >
                <img
                  className="ViewOurAppBadgeStyle"
                  alt="google"
                  src={google}
                />
              </a>
            </Col>
          </Row>
          <Row>
            <Col xs={10} sm={10} md={12} lg={{ size: '4', order: '2' }}>
              <img
                className={'ViewOurAppImgTwo img-fluid'}
                src={
                  manifest['stylesheet'] && manifest['stylesheet'].images
                    ? manifest['stylesheet'].images[config.screenshot]['url']
                    : ''
                }
                alt={
                  manifest['stylesheet'] && manifest['stylesheet'].images
                    ? manifest['stylesheet'].images[config.screenshot][
                        'altText'
                      ]
                    : ''
                }
                width={325}
                height={638}
              />
            </Col>
            <Col
              className={'align-items-center'}
              xs={10}
              sm={10}
              md={5}
              lg={{ size: '4', order: '1' }}
            >
              {config.leftFeatures.map(
                (feature: {
                  title: React.ReactNode
                  description: React.ReactNode
                  iconViewType: any
                  iconType: any
                }) => (
                  <div key={feature.iconType}>
                    <Paper className="ViewOurAppPaperTwo">
                      <div className="flex flex-row items-center p-1">
                        <FontAwesomeIcon
                          size="2x"
                          color={config?.iconColor}
                          icon={[feature.iconViewType, feature.iconType]}
                        />
                        <h2 className="ViewOurAppH5 font-semibold">
                          {feature.title}
                        </h2>
                      </div>
                      <p className="text-sm">{feature.description}</p>
                    </Paper>
                  </div>
                ),
              )}
            </Col>
            <Col
              className={'align-items-center'}
              xs={10}
              sm={10}
              md={5}
              lg={{ size: '4', order: '3' }}
            >
              {config.rightFeatures.map(
                (feature: {
                  iconViewType: any
                  iconType: any
                  title: React.ReactNode
                  description: React.ReactNode
                }) => (
                  <div key={feature.iconType}>
                    <Paper className="ViewOurAppPaperThree">
                      <div className="flex flex-row items-center p-1">
                        <FontAwesomeIcon
                          size="2x"
                          color={config?.iconColor}
                          icon={[feature.iconViewType, feature.iconType]}
                        />
                        <h2 className="ViewOurAppH5 font-semibold">
                          {feature.title}
                        </h2>
                      </div>
                      <p className="text-sm">{feature.description}</p>
                    </Paper>
                  </div>
                ),
              )}
            </Col>
          </Row>
        </Paper>
      </Col>
    </Row>
  )
}
