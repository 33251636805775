import * as S from './styles';
import { StyledLink, ExternalLink } from '../../Links/Links';
import { ListItem } from '../styles';

export const Submenu = ({ featuresList, item, colors }: any) => {
  return (
    <S.DropdownContainer>
      <S.DropdownMenu>
        <S.DropdownList
          $primary={colors.primaryColor}
          $tertiary={colors.tertiaryColor}
        >
          {featuresList[item]?.dropdown?.map(
            (dropdownItem: any, index: number) => (
              <ListItem
                tabIndex={0}
                key={featuresList[dropdownItem].title + index}
              >
                {featuresList[dropdownItem]?.external === true ? (
                  <ExternalLink
                    href={featuresList[dropdownItem].url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {featuresList[dropdownItem].title}
                  </ExternalLink>
                ) : (
                  <StyledLink to={dropdownItem}>
                    {featuresList[dropdownItem].title}
                  </StyledLink>
                )}
              </ListItem>
            )
          )}
        </S.DropdownList>
      </S.DropdownMenu>
    </S.DropdownContainer>
  );
};
