import React, { useState } from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import OCVVideoPlayer from '../../../OCVFeatures/OCVVideoPlayer/OCVVideoPlayer'
import FacebookWidget from '../../FacebookWidget'
import TwitterWidget from '../../TwitterWidget'
import OCVBlogSlider from '../../../OCVFeatures/OCVBlog/OCVBlogSlider/OCVBlogSlider'
import HorizontalSublayout from '../../HorizontalSublayout/HorizontalSublayout'
import IconButton from '@material-ui/core/IconButton/IconButton'
import ReactModal from 'react-modal'
import './Feature.css'

ReactModal.setAppElement('#root')
library.add(fab, far, fal, fad, fas)

export default function Feature(props: any) {
  const [showModal, setShowModal] = useState(false)
  const [modalView, setModalView] = useState(
    Object.keys(props.manifestData['views'])[0],
  )
  const [modalTitle, setModalTitle] = useState('')

  const handleOpenModal = (view: string, title: string) => {
    setShowModal(true)
    setModalView(view)
    setModalTitle(title)
  }
  const handleCloseModal = () => {
    setShowModal(false)
  }

  const getView = (view: string, manifestData: any, classes: any) => {
    switch (manifestData['views'][view]['type']) {
      case 'imageView':
        return (
          <img
            className={'FeatureImageOne img-fluid'}
            src={
              manifestData['stylesheet'].images
                ? manifestData['stylesheet'].images[
                    manifestData['views'][view]['config']['image']
                  ]['url']
                : ''
            }
            alt={
              manifestData['stylesheet'].images
                ? manifestData['stylesheet'].images[
                    manifestData['views'][view]['config']['image']
                  ]['altText']
                : ''
            }
          />
        )
      case 'button':
        return (
          <div
            style={{
              paddingTop: manifestData['views'][view]['config']['paddingTop']
                ? manifestData['views'][view]['config']['paddingTop']
                : 0,
              paddingBottom: manifestData['views'][view]['config'][
                'paddingBottom'
              ]
                ? manifestData['views'][view]['config']['paddingBottom']
                : 0,
              paddingLeft: manifestData['views'][view]['config']['paddingLeft']
                ? manifestData['views'][view]['config']['paddingLeft']
                : 0,
              paddingRight: manifestData['views'][view]['config'][
                'paddingRight'
              ]
                ? manifestData['views'][view]['config']['paddingRight']
                : 0,
            }}
          >
            {manifestData['features'][
              manifestData['views'][view]['config']['feature']
            ]['type'] === 'modal' ? (
              <button
                style={{
                  background: manifestData['views'][view]['config'][
                    'backgroundColor'
                  ]
                    ? manifestData['views'][view]['config']['backgroundColor']
                    : manifestData['stylesheet']['colors']['primary'],
                }}
                type={'button'}
                onClick={() =>
                  handleOpenModal(
                    manifestData['features'][
                      manifestData['views'][view]['config']['feature']
                    ]['view'],
                    manifestData['features'][
                      manifestData['views'][view]['config']['feature']
                    ]['title'],
                  )
                }
                className={classes.homepageButton}
              >
                {manifestData['views'][view]['config']['title']}
              </button>
            ) : manifestData['features'][
                manifestData['views'][view]['config']['feature']
              ]['type'] !== 'webview' ||
              !manifestData['features'][
                manifestData['views'][view]['config']['feature']
              ]['subtype'].includes('external') ? (
              <Link to={manifestData['views'][view]['config']['feature']}>
                <button
                  style={{
                    background: manifestData['views'][view]['config'][
                      'backgroundColor'
                    ]
                      ? manifestData['views'][view]['config']['backgroundColor']
                      : manifestData['stylesheet']['colors']['primary'],
                  }}
                  type={'button'}
                  className={classes.homepageButton}
                >
                  {manifestData['views'][view]['config']['title']}
                </button>
              </Link>
            ) : (
              <a
                target={'_blank'}
                rel="noopener noreferrer"
                href={
                  manifestData['features'][
                    manifestData['views'][view]['config']['feature']
                  ]['url']
                }
              >
                <button
                  style={{
                    background: manifestData['views'][view]['config'][
                      'backgroundColor'
                    ]
                      ? manifestData['views'][view]['config']['backgroundColor']
                      : manifestData['stylesheet']['colors']['primary'],
                  }}
                  type={'button'}
                  className={classes.homepageButton}
                >
                  {manifestData['views'][view]['config']['title']}
                </button>
              </a>
            )}
          </div>
        )
      case 'label':
        return (
          <p
            dangerouslySetInnerHTML={{
              __html: manifestData['views'][view]['config']['text'],
            }}
          />
        )
      case 'horizontalLayout':
        return (
          <HorizontalSublayout
            classes={classes}
            manifestData={manifestData}
            config={manifestData['views'][view]['config']}
          />
        )
      case 'video':
        return <OCVVideoPlayer config={manifestData['views'][view]['config']} />
      case 'facebookWidget':
        return <FacebookWidget config={manifestData['views'][view]['config']} />
      case 'twitterWidget':
        return <TwitterWidget config={manifestData['views'][view]['config']} />
      case 'blogSlider':
        return (
          <OCVBlogSlider
            link={
              manifestData['features'][
                manifestData['views'][view]['config']['feature']
              ]['url']
            }
          />
        )
    }
  }

  const {
    classes,
    width,
    height,
    color,
    iconType,
    title,
    feature,
    type,
    image,
    bg,
    iconViewType,
  } = props
  const icon =
    image === undefined ? (
      <FontAwesomeIcon size="2x" icon={[iconViewType, iconType]} />
    ) : (
      <img
        alt={title}
        className="FeatureImageTwo"
        width={parseInt(width) + 20}
        height={parseInt(height) + 20}
        src={image}
      />
    )
  return (
    <>
      {type === 'webview' && props.subtype.includes('external') ? (
        <a
          style={{ backgroundColor: bg }}
          className={`flex flex-row items-center justify-evenly  rounded-full py-3 w-72 my-1 ${classes.featureLink}`}
          href={props.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="w-1/4">{icon}</div>
          <p className={'w-3/4 uppercase text-sm font-semibold'}>{title}</p>
        </a>
      ) : type === 'modal' ? (
        <span
          style={{ backgroundColor: bg }}
          className={`flex flex-row items-center justify-evenly  rounded-full py-3 w-72 my-1 ${classes.featureLink}`}
          onClick={() =>
            handleOpenModal(
              props.manifestData['features'][feature]['view'],
              props.manifestData['features'][feature]['title'],
            )
          }
        >
          <div className="w-1/4">{icon}</div>
          <p className={'w-3/4 uppercase text-sm font-semibold'}>{title}</p>
        </span>
      ) : (
        <Link
          style={{ backgroundColor: bg }}
          className={`flex flex-row items-center justify-evenly  rounded-full py-3 w-72 my-1 ${classes.featureLink}`}
          to={'/' + feature}
        >
          <div 
            style={{ color: color }}
            className="w-1/4">
            {icon}
          </div>
          <p className={'w-3/4 uppercase text-sm font-semibold text-black'}>
            {title}
          </p>
        </Link>
      )}
      <ReactModal
        className="FeatureModalContent"
        overlayClassName="FeatureModal"
        contentLabel="Minimal Modal Example"
        onRequestClose={() => handleCloseModal()}
        isOpen={showModal}
      >
        <div className="FeatureInternalDiv">
          <h4 className="FeatureModalTitle">{modalTitle}</h4>
          <IconButton
            aria-label="Close"
            className="FeatureCloseButton"
            onClick={() => handleCloseModal()}
          >
            <FontAwesomeIcon className="FeatureIcon" icon={['fas', 'times']} />
          </IconButton>
        </div>
        {getView(modalView, props.manifestData, classes)}
      </ReactModal>
    </>
  )
}
