import React from 'react'

export const SearchBar = ({ value, placeholder, onChange }) => {
  return (
    <input
      className="w-5/6 mx-auto p-2 rounded-lg bg-gray-50 focus:bg-white focus:ring-blue-400 focus:outline-none"
      type="search"
      value={value}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
    />
  )
}
