import React from "react";
import PaginationArrow from "./Pagination/PaginationArrow";
import { Link } from "react-router-dom";

export default function BlogCarousel(props: any) {
  const [selected, setSelected] = React.useState<any>(0);
  const [cycle, setCycle] = React.useState(false);
  const [blogData, setBlogData] = React.useState<any>([]);
  const [testLimit, setTestLimit] = React.useState(0);

  React.useEffect(() => {
    fetch(
      props.manifestData["features"][props.viewData.config["feature"]]["url"],
      {
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setBlogData(data);
        setCycle(props.viewData?.config?.cycle);
        setTestLimit(props.viewData?.config?.testimonialLimit);
        setSelected(data[0] ? data[0]["_id"]["$id"] : "");
      });
  }, [props.manifestData, props.viewData]);

  React.useEffect(() => {
    if (cycle && blogData.length > 0) {
      setTimeout(() => {
        return blogData.forEach((info: any, i: any) => {
          if (selected === info["_id"]["$id"]) {
            if (selected === blogData[testLimit]["_id"]["$id"]) {
              setSelected(blogData[0]["_id"]["$id"]);
            } else {
              setSelected(blogData[i + 1]["_id"]["$id"]);
            }
          }
          return null;
        });
      }, props.viewData.config.timer);
    }
  }, [selected, cycle, props.viewData.config.timer, testLimit, blogData]);

  function stopTimer() {
    clearTimeout(
      setTimeout(() => {
        return blogData.forEach((info: any, i: any) => {
          if (selected === info["_id"]["$id"]) {
            if (selected === blogData[testLimit]["_id"]["$id"]) {
              setSelected(blogData[0]["_id"]["$id"]);
            } else {
              setSelected(blogData[i + 1]["_id"]["$id"]);
            }
          }
          return null;
        });
      }, props.viewData.config.timer)
    );
  }
  return selected !== 0 ? (
    <div
      id={props.anchorID}
      style={{
        background: props.viewData.config.bgColor,
        color: props.viewData.config.textColor,
      }}
      className="w-full h-full flex flex-col justify-center items-center"
    >
      <div className="text-center font-bold w-full my-4">
        <h1 className="w-full text-2xl sm:text-3xl md:text-4xl text-center">
          {props.viewData.title.toUpperCase()}
        </h1>
        <hr
          className="mx-auto w-32 mt-2 py-0.5"
          style={{ background: props.viewData.config.hrColor }}
        />
      </div>

      <div
        className={`w-full md:h-80 flex flex-col-reverse justify-center items-center mx-auto mb-4`}
      >
        <div
          className={`h-full w-full max-w-6xl text-left px-3 flex flex-col-reverse ${
            props.viewData.config.image.position === "right"
              ? "sm:flex-row"
              : "sm:flex-row-reverse"
          } justify-evenly items-center space-y-3`}
        >
          <div className="h-full flex flex-col space-y-4 max-w-4xl flex-1 items-center">
            <Link
              to={
                "/" +
                props.viewData.config.feature +
                "/" +
                blogData.find((item: any) => item["_id"]["$id"] === selected)[
                  "_id"
                ]["$id"]
              }
              className={`flex h-full overflow-y-hidden flex-col sm:items-start sm:justify-start px-2 hover:no-underline hover:text-current`}
            >
              {blogData
                ?.find((item: any) => item["_id"]["$id"] === selected)
                ?.title?.trim() ? (
                <h1 className="text-xl  mx-auto text-center font-bold leading-5 mb-4">
                  {blogData
                    ?.find((item: any) => item["_id"]["$id"] === selected)
                    ?.title?.trim()}
                </h1>
              ) : null}

              <p
                dangerouslySetInnerHTML={{
                  __html: blogData.find(
                    (item: any) => item["_id"]["$id"] === selected
                  )?.content,
                }}
                className="text-xs md:text-sm flex flex-col text-center"
              ></p>
            </Link>
            <div className="flex flex-col sm:flex-row items-center md:justify-start space-y-4 sm:space-x-8 sm:space-y-0">
              <div className="">
                <PaginationArrow
                  onClickLeft={() => {
                    setCycle(false);
                    stopTimer();
                    const index = blogData.findIndex(
                      (blog: any) => blog["_id"]["$id"] === selected
                    );

                    if (selected === blogData[0]["_id"]["$id"]) {
                      setSelected(blogData[testLimit]["_id"]["$id"]);
                    } else {
                      setSelected(blogData[index - 1]["_id"]["$id"]);
                    }
                  }}
                  onClickRight={() => {
                    setCycle(false);
                    stopTimer();
                    const index = blogData.findIndex(
                      (blog: any) => blog["_id"]["$id"] === selected
                    );

                    if (selected === blogData[testLimit]["_id"]["$id"]) {
                      setSelected(blogData[0]["_id"]["$id"]);
                    } else {
                      setSelected(blogData[index + 1]["_id"]["$id"]);
                    }
                  }}
                  selected={selected}
                  paginationColor={props.viewData.config.pagination}
                ></PaginationArrow>
              </div>
              {props.viewData.config.viewAll === true ? (
                <>
                  <div className="flex-1 flex h-full">
                    <Link
                      to={"/" + props.viewData.config.feature}
                      className={"hover:text-current"}
                    >
                      <button
                        style={{
                          background: props.viewData.config.bgColor,
                          borderColor: props.viewData.config.textColor,
                        }}
                        type="button"
                        className="rounded-full hover:no-underline h-full px-8 py-2 border-2 mx-auto text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        <div
                          className={
                            " w-full text-center xl:text-lg tracking-tighter"
                          }
                        >
                          {props.viewData.config.button.text}
                        </div>
                      </button>
                    </Link>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="flex-1 3xl:flex-none flex justify-center mb-2 md:mb-0">
            <img
              className="text-white rounded-md object-left mx-auto object-contain h-72"
              src={
                blogData.find((item: any) => item["_id"]["$id"] === selected)
                  ?.images[0]?.large
              }
              alt="Phone Feature"
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
