import React from "react";

declare global {
  interface Window {
    fbAsyncInit: any;
    FB: any;
  }
  interface Element {
    src: string;
    async: boolean;
    defer: boolean;
  }
}

export default function FacebookWidget(props: any) {
  const { config } = props;
  return (
    <div
      style={{ textAlign: "center", width: "100%" }}
      className="max-w-screen"
      id={props.anchorID}
    >
      <iframe
        title={"Facebook"}
        src={config["link"]}
        style={{ position: "relative", border: "none" }}
        width={"700px"}
        height={"700px"}
        className="w-full"
      />
    </div>
  );
}
