import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import OCVVideoPlayer from "../../OCVFeatures/OCVVideoPlayer/OCVVideoPlayer";
import FacebookWidget from "../FacebookWidget";
import TwitterWidget from "../TwitterWidget";
import ReactModal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "@material-ui/core/IconButton/IconButton";
import OCVBlogSlider from "../../OCVFeatures/OCVBlog/OCVBlogSlider/OCVBlogSlider";
import "./HorizontalSublayout.css";

ReactModal.setAppElement("#root");

export default function HorizontalSublayout(props: any) {
  const [showModal, setShowModal] = useState(false);
  const [modalView, setModalView] = useState(props.config["items"][0]["view"]);
  const [modalTitle, setModalTitle] = useState("");

  const handleOpenModal = (view: string, title: string) => {
    setShowModal(true);
    setModalView(view);
    setModalTitle(title);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const getView = (view: string, manifestData: any, classes: any) => {
    switch (manifestData["views"][view]["type"]) {
      case "imageView":
        return (
          <img
            className={"HorizontalSublayoutImg img-fluid"}
            src={
              manifestData["stylesheet"].images
                ? manifestData["stylesheet"].images[
                    manifestData["views"][view]["config"]["image"]
                  ]["url"]
                : ""
            }
            alt={
              manifestData["stylesheet"].images
                ? manifestData["stylesheet"].images[
                    manifestData["views"][view]["config"]["image"]
                  ]["altText"]
                : ""
            }
          />
        );
      case "button":
        return (
          <div
            style={{
              paddingTop: manifestData["views"][view]["config"]["paddingTop"]
                ? manifestData["views"][view]["config"]["paddingTop"]
                : 0,
              paddingBottom: manifestData["views"][view]["config"][
                "paddingBottom"
              ]
                ? manifestData["views"][view]["config"]["paddingBottom"]
                : 0,
              paddingLeft: manifestData["views"][view]["config"]["paddingLeft"]
                ? manifestData["views"][view]["config"]["paddingLeft"]
                : 0,
              paddingRight: manifestData["views"][view]["config"][
                "paddingRight"
              ]
                ? manifestData["views"][view]["config"]["paddingRight"]
                : 0,
            }}
          >
            {manifestData["features"][
              manifestData["views"][view]["config"]["feature"]
            ]["type"] === "modal" ? (
              <button
                style={{
                  background: manifestData["views"][view]["config"][
                    "backgroundColor"
                  ]
                    ? manifestData["views"][view]["config"]["backgroundColor"]
                    : manifestData["stylesheet"]["colors"]["primary"],
                }}
                type={"button"}
                onClick={() =>
                  handleOpenModal(
                    manifestData["features"][
                      manifestData["views"][view]["config"]["feature"]
                    ]["view"],
                    manifestData["features"][
                      manifestData["views"][view]["config"]["feature"]
                    ]["title"]
                  )
                }
                className={classes.homepageButton}
              >
                {manifestData["views"][view]["config"]["title"]}
              </button>
            ) : manifestData["features"][
                manifestData["views"][view]["config"]["feature"]
              ]["type"] !== "webview" ||
              !manifestData["features"][
                manifestData["views"][view]["config"]["feature"]
              ]["subtype"].includes("external") ? (
              <Link to={manifestData["views"][view]["config"]["feature"]}>
                <button
                  style={{
                    background: manifestData["views"][view]["config"][
                      "backgroundColor"
                    ]
                      ? manifestData["views"][view]["config"]["backgroundColor"]
                      : manifestData["stylesheet"]["colors"]["primary"],
                  }}
                  type={"button"}
                  className={classes.homepageButton}
                >
                  {manifestData["views"][view]["config"]["title"]}
                </button>
              </Link>
            ) : (
              <a
                target={"_blank"}
                rel="noopener noreferrer"
                href={
                  manifestData["features"][
                    manifestData["views"][view]["config"]["feature"]
                  ]["url"]
                }
              >
                <button
                  style={{
                    background: manifestData["views"][view]["config"][
                      "backgroundColor"
                    ]
                      ? manifestData["views"][view]["config"]["backgroundColor"]
                      : manifestData["stylesheet"]["colors"]["primary"],
                  }}
                  type={"button"}
                  className={classes.homepageButton}
                >
                  {manifestData["views"][view]["config"]["title"]}
                </button>
              </a>
            )}
          </div>
        );
      case "label":
        return (
          <p
            dangerouslySetInnerHTML={{
              __html: manifestData["views"][view]["config"]["text"],
            }}
          />
        );
      case "horizontalLayout":
        return (
          <HorizontalSublayout
            classes={classes}
            manifestData={manifestData}
            config={manifestData["views"][view]["config"]}
          />
        );
      case "video":
        return (
          <OCVVideoPlayer config={manifestData["views"][view]["config"]} />
        );
      case "facebookWidget":
        return (
          <FacebookWidget config={manifestData["views"][view]["config"]} />
        );
      case "twitterWidget":
        return <TwitterWidget config={manifestData["views"][view]["config"]} />;
      case "blogSlider":
        return (
          <OCVBlogSlider
            link={
              manifestData["features"][
                manifestData["views"][view]["config"]["feature"]
              ]["url"]
            }
          />
        );
    }
  };

  const { config, manifestData, classes, anchorID } = props;
  return (
    <Row
      id={anchorID}
      style={{
        paddingTop: config["paddingTop"] ? config["paddingTop"] : 0,
        paddingBottom: config["paddingBottom"] ? config["paddingBottom"] : 0,
        paddingLeft: config["paddingLeft"] ? config["paddingLeft"] : 0,
        paddingRight: config["paddingRight"] ? config["paddingRight"] : 0,
      }}
      className={config?.additionalTailwindStyles}
    >
      {config["items"].map((item: any, index: any) => (
        <Col key={item.view + item.weight + index} xs={10} sm={item.weight}>
          {getView(item.view, manifestData, classes)}
        </Col>
      ))}
      <ReactModal
        className="HorizontalSublayoutModalContent"
        overlayClassName="HorizontalSublayoutModal"
        contentLabel="Minimal Modal Example"
        onRequestClose={() => handleCloseModal()}
        isOpen={showModal}
      >
        <div className="HorizontalSublayoutDiv">
          <h4 className="HorizontalSublayoutModalTitle">{modalTitle}</h4>
          <IconButton
            aria-label="Close"
            className="HorizontalSublayoutCloseButton"
            onClick={() => handleCloseModal()}
          >
            <FontAwesomeIcon
              className="HorizontalSublayoutIcon"
              icon={["fas", "times"]}
            />
          </IconButton>
        </div>
        {getView(modalView, manifestData, classes)}
      </ReactModal>
    </Row>
  );
}
