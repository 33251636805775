import styled from 'styled-components';
import { ColorProps } from '../styles';

export const AccordionItems = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 18px;

  & > a {
    padding: 0.5rem 0.75rem 0.5rem 1.25rem;
  }

  & > details > div > a {
    padding: 0.5rem 0.75rem 0.5rem 1.75rem;
  }
`;

export const AccordionContainer = styled.details<ColorProps>`
  font-family: Roboto;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid rgb(255, 255, 255, 0.4);
  }

  & > summary::-webkit-details-marker {
    display: none;
  }

  & > summary::after {
    content: '';
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    transition: all 0.25s ease;
    border-bottom: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
    float: right;
  }

  &[open] > summary::after {
    content: '';
    transform: rotate(-135deg);
  }

  & > ${AccordionItems} {
    background: ${({ subColor }) => subColor};
  }
`;

export const AccordionHeader = styled.summary`
  font-size: 18px;
  font-weight: 400;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0.75rem;

  div {
    padding-right: 1.5rem;
  }
`;
