import styled from "styled-components/macro";
import { Link } from "react-router-dom";

interface Props {
  bgColor?: string;
  textColor?: string;
  fontFam?: string;
  buttonColor?: string;
  buttonText?: string;
}

export const OuterWrapper = styled.section<Props>`
  box-sizing: border-box;
  padding: 1.5rem;
  font-family: "Roboto", sans-serif;
  font-size: 2.25rem;
  line-height: 2.5rem;

  background-color: ${({ bgColor }) => bgColor || "#FFF"};
  color: ${({ textColor }) => textColor || "#000"};
`;
export const InnerWrapper = styled.div`
  overflow: hidden;
  margin: 0 auto;

  @media (min-width: 1024px) {
    max-width: 80rem;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeadingText = styled.h2`
  color: ${({ textColor }) => textColor || "#000"};
  font-family: ${({ fontFam }) => fontFam || "Fjalla One"};
  font-weight: 800;
  font-size: 2.25rem;
  line-height: 2.5rem;
  letter-spacing: -0.025em;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const ImgMockup = styled.img`
  height: auto;
  width: 50%;
  display: block;
  padding-top: 2rem;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  overflow: hidden;
  height: auto;

  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    &[data-hidden="true"] {
      display: none;
    }
  }
`;
export const Flex = styled(FlexContainer)`
  justify-content: space-around;
  margin-bottom: 15px;
`;

export const LinkButton = styled(Link)`
  background-color: ${({ buttonColor }) => buttonColor || "#1a2f50"};
  color: ${({ buttonText }) => buttonText || "#fff"};

  text-transform: uppercase;
  font-weight: 800;
  font-size: 1rem;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  border-radius: 0.375rem;
  display: inline-flex;

  &:hover {
    color: ${({ buttonText }) => buttonText || "#fff"};
    text-decoration: none;
  }
`;
export const TextDesc = styled.p`
  text-transform: uppercase;
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.75rem;
  padding: 2rem;
  font-weight: 600;
`;

export const ImgQRCode = styled.img`
  width: 9rem;
`;

export const CaptionQRCode = styled.p`
  text-transform: uppercase;
  font-weight: 800;
  font-size: 1rem;
  font-family: inherit;
`;
export const ImgDownloadApp = styled.img`
  width: 9rem;
  padding-top: 1rem;
`;
