import React from "react";

export default function OCVVideoPlayer(props: any) {
  const { config } = props;
  return (
    <div
      className={`flex justify-center py-12 ${config?.additionalTailwindStyles}`}
    >
      <iframe
        width="80%"
        height="500"
        src={config["link"]}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      ></iframe>
    </div>
  );
}
