import React from "react";

export default function Summary(props: any) {
  const [blogData, setBlogData] = React.useState<any>([]);
  React.useEffect(() => {
    fetch(props.manifestData["features"][props.viewData["feature"]]["url"], {
      mode: "cors",
    })
      .then((response) => response.json())
      .then((data) => {
        setBlogData(data);
      });
  }, [props.manifestData, props.viewData]);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const handleDates = (date: any) => {
    const d = new Date(date * 1000);
    const newDate =
      months[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
    return newDate;
  };

  const config = props?.viewData?.config;
  let backgroundImageConfig = undefined;

  if (config?.rgba1 && config?.rgba2 && config?.backgroundImageURL)
    backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2}), url(${config.backgroundImageURL})`;
  else if (config?.rgba1 && config?.rgba2)
    backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2})`;
  else if (config?.backgroundImageURL)
    backgroundImageConfig = `url(${config.backgroundImageURL})`;

  return (
    <div
      id={props.anchorID}
      className={`pt-16 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8 bg-cover ${config?.additionalTailwindStyles}`}
      style={{
        backgroundColor: "#eee",
        backgroundImage: backgroundImageConfig,
      }}
    >
      <div className="relative overflow-hidden max-w-lg mx-auto lg:max-w-7xl h-auto">
        <div className="flex flex-col items-center justify-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl uppercase">
            {props.viewData.title}
          </h2>
          <div
            style={{ borderColor: props.viewData.config.divideColor }}
            className="mt-3 mb-2 border-b-4 w-1/6"
          />
        </div>
        <div className="mt-3 h-auto grid gap-8 pt-2 lg:grid-cols-2 lg:gap-x-24 overflow-hidden">
          <div className="flex flex-col overflow-hidden">
            {blogData.length > 0 && (
              <>
                <h3 className="font-semibold mb-1 mt-4">
                  {handleDates(blogData[0]?.date.sec)}
                </h3>
                <div
                  style={{ borderColor: props.viewData.config.blogDivideColor }}
                  className="border-t-2 w-1/12 mb-2"
                />
              </>
            )}
            <p className="text-xl font-semibold text-gray-900">
              {blogData[0]?.title}
            </p>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  blogData[0]?.content.length > 200
                    ? blogData[0]?.content.substring(0, 200) +
                      "<span>...</span>"
                    : blogData[0]?.content,
              }}
              className="mt-3 text-base text-gray-500"
            />
            <a
              className="mt-2 underline text-blue-500"
              href={`${props.viewData["feature"]}/${blogData[0]?._id["$id"]}`}
            >
              Read more
            </a>
          </div>
          <div className="flex flex-col overflow-hidden">
            {blogData.length > 0 && (
              <>
                <h3 className="font-semibold mb-1 mt-4">
                  {handleDates(blogData[1]?.date.sec)}
                </h3>
                <div
                  style={{ borderColor: props.viewData.config.blogDivideColor }}
                  className="border-t-2 w-1/12 mb-2"
                ></div>
              </>
            )}
            <p className="text-xl font-semibold text-gray-900">
              {blogData[1]?.title}
            </p>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  blogData[1]?.content.length > 200
                    ? blogData[1]?.content.substring(0, 200) +
                      "<span>...</span>"
                    : blogData[1]?.content,
              }}
              className="mt-3 text-base text-gray-500"
            />
            <a
              className="mt-2 underline text-blue-500"
              href={`${props.viewData["feature"]}/${blogData[1]?._id["$id"]}`}
            >
              Read more
            </a>
          </div>
        </div>
        <div className="text-center mt-12 lg:mb-0 mb-6">
          <a href={props.viewData["feature"]} className="w-auto">
            <button
              type="button"
              style={{
                color: props.viewData["config"]["buttonTextColor"]
                  ? props.viewData["config"]["buttonTextColor"]
                  : "white",
                background: props.viewData["config"]["buttonColor"]
                  ? props.viewData["config"]["buttonColor"]
                  : "blue",
              }}
              className="uppercase inline-flex items-center px-12 py-3 border-2 border-black text-lg font-semibold shadow-sm"
            >
              View All {props.viewData["title"]}
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}
