import { StyledLink } from '../../Links/Links';
import { DropdownArrow } from '../styles';
import { Submenu } from '../Submenu/Submenu';
import { MegaMenu } from '../MegaMenu/MegaMenu';

export const Dropdown = ({ featuresList, item, index, colors }: any) => {
  let header, drop, content;
  header = (
    <StyledLink as="span" key={featuresList[item].navHeader + index}>
      {featuresList[item].navHeader}
      <DropdownArrow></DropdownArrow>
    </StyledLink>
  );

  if (featuresList[item].type === 'submenu')
    drop = (
      <Submenu
        featuresList={featuresList}
        item={item}
        key={featuresList[item].type + index}
        colors={colors}
      />
    );
  else {
    drop = (
      <MegaMenu
        featuresList={featuresList}
        item={item}
        key={featuresList[item].type + index}
        colors={colors}
      />
    );
  }

  content = [header, drop];

  return <>{content}</>;
};
