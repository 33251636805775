import React from "react";

export default function OCVBlogCardWidget(props: any) {
  const { viewData } = props;

  const [blogData, setBlogData] = React.useState<any>([]);
  React.useEffect(() => {
    fetch(viewData.url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => setBlogData(data));
  }, [viewData]);

  const config = viewData?.config;
  let backgroundImageConfig = undefined;

  if (config?.rgba1 && config?.rgba2 && config?.backgroundImageURL)
    backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2}), url(${config.backgroundImageURL})`;
  else if (config?.rgba1 && config?.rgba2)
    backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2})`;
  else if (config?.backgroundImageURL)
    backgroundImageConfig = `url(${config.backgroundImageURL})`;

  return (
    <div
      id={props.anchorID}
      className={`bg-cover pt-1 ${config?.additionalTailwindStyles}`}
      style={{
        backgroundColor: "#eee",
        backgroundImage: backgroundImageConfig,
      }}
    >
      <ul className="space-y-6 py-4">
        {blogData.map((item: any, index: number) =>
          index % 2 === 1 ? (
            <li
              className="bg-white shadow rounded-md px-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 justify-center overflow-y-hidden max-h-full lg:max-h-72 xl:max-h-96 w-full lg:w-2/3 mx-auto"
              key={index}
            >
              <div className="bg-white col-span-1 lg:col-span-2 py-2 lg:py-4 border-b border-gray-200 px-3 sm:px-6">
                <div className="inline-block">
                  <h2 className="text-md lg:text-2xl leading-6 font-bold text-gray-900 pb-1">
                    {item?.title}
                  </h2>
                  <hr className="border-4 border-yellow-300 w-1/12 pb-2 lg:pb-6" />
                </div>
                <div className="text-sm text-gray-500">
                  {viewData.config.date &&
                    new Date(item?.date.sec * 1000).toLocaleString()}
                </div>
                <div
                  className="mt-1 text-sm sm:text-md text-gray-500"
                  dangerouslySetInnerHTML={{
                    __html:
                      viewData.config.truncate && item?.content.length > 800
                        ? item?.content.slice(0, 800) + "<span>...</span>"
                        : item?.content,
                  }}
                ></div>
              </div>
              <img
                className="h-auto max-h-60 w-auto sm:h-auto place-self-center sm:place-self-center sm:w-full col-span-1 block py-2 row-start-1 sm:row-start-auto"
                alt=""
                src={item?.images[0]?.large}
              />
            </li>
          ) : (
            <li
              className="bg-white shadow rounded-md px-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 justify-center overflow-y-hidden max-h-full lg:max-h-72 xl:max-h-96 w-full lg:w-2/3 mx-auto"
              key={index}
            >
              <img
                className="h-auto max-h-60 w-auto sm:h-auto place-self-center sm:place-self-center sm:w-full col-span-1 block py-2 row-start-1 sm:row-start-auto"
                alt=""
                src={item?.images[0]?.large}
              />
              <div className="bg-white col-span-1 lg:col-span-2 py-2 lg:py-4 border-b border-gray-200 px-3 sm:px-6">
                <div className="inline-block">
                  <h2 className="text-md lg:text-2xl leading-6 font-bold text-gray-900 pb-1">
                    {item?.title}
                  </h2>
                  <hr className="border-4 border-yellow-300 w-1/12 pb-2 lg:pb-6" />
                </div>
                <div className="text-sm text-gray-500">
                  {viewData.config.date &&
                    new Date(item?.date.sec * 1000).toLocaleString()}
                </div>
                <div
                  className="mt-1 text-sm sm:text-md text-gray-500"
                  dangerouslySetInnerHTML={{
                    __html:
                      viewData.config.truncate && item?.content.length > 800
                        ? item?.content.slice(0, 800) + "<span>...</span>"
                        : item?.content,
                  }}
                ></div>
              </div>
            </li>
          )
        )}
      </ul>
    </div>
  );
}
