export const OCVImage = (props: any) => {
  const { image } = props;
  return (
    <div
      style={{
        backgroundColor: "#fff",
      }}
      className="flex justify-center items-center p-4"
    >
      <img src={image} alt="" loading="lazy" />
    </div>
  );
};
