import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import DetailView from "./DetailView";
import NewsDigestList from "./NewsDigestList";
export default withRouter(function DigestContainer({
  key,
  viewData,
  route,
  manifestData,
}: any) {
  interface DigestImage {
    small?: string;
    large?: string;
  }

  interface BlogID {
    $id: string;
  }

  interface BlogDate {
    sec: number;
    usec: number;
  }

  interface ICalendar {
    epoch: number;
    type: 4;
    feedID: number;
    origin: number;
    eventID: string;
    calendarID: string;
    calendarTitle: string;
    title: string;
    content: any;
    summary: string;
    images: DigestImage[];
  }

  interface ITwitter {
    epoch: number;
    type: 2;
    feedID: number;
    origin: number;
    /**
     * The @ of the twitter user
     */
    title: string;
    /**
     * Content of twitter post. Can contain HTML prefer to use summary if possible
     */
    content: any;
    /**
     * Summary of the twitter post. Does not contain HTML. Cuts off after certain amount of characters.
     */
    summary: string;
    images: DigestImage[];
    /**
     * Profile image of twitter user. Used if tweet does not contain images.
     */
    profile_image_url_https: string;
    /**
     * ID of specific post. Append to link after the @ for the twitter user (remove @ symbol) and add /status/ between the two. EX: https://twitter.com/ocvtwitter/status/12341235145413
     */
    id_str: string;
    link: string;
  }

  interface IFacebook {
    epoch: number;
    type: 3;
    feedID: number;
    origin: number;
    /**
     * The PageID and PostID are contained in this key. UserID is before underscore. PostID is after underscore. EX: 109290340297987_0190234578001293. Use like this: https://facebook.com/109290340297987_0190234578001293
     */
    facebookPostID: string;
    /**
     * Facebook page/user title
     */
    title: string;
    /**
     * Content of twitter post. Can contain HTML prefer to use summary if possible
     */
    content: any;
    /**
     * Summary of the twitter post. Does not contain HTML. Cuts off after certain amount of characters.
     */
    summary: string;
    /**
     * Profile image of facebook page. Used if there are no images in the post.
     */
    fb_profile_image: string;
    status_type: string;
    images: DigestImage[];
  }

  interface IBlog {
    _id: BlogID;
    /**
     * Email of blog post creator.
     */
    creator: string;
    date: BlogDate;
    /**
     * Title of blog post.
     */
    title: string;
    /**
     * Content of blog post. Can contain HTML prefer to use summary.
     */
    content: any;
    /**
     * Different ID than the _id: { $id: number }
     */
    blogID: number;
    images: DigestImage[];
    status: number;
    epoch: number;
    type: 1;
    feedID: number;
    origin: number;
    /**
     * Summary of blog post
     */
    summary: string;
    /**
     * Title of the blog that this post came from
     */
    blogTitle: string;
    blogIdentifier: string;
  }

  interface IPush {
    epoch: number;
    type: 5;
    feedID: number;
    origin: number;
    /**
     * Title of push notification
     */
    title: string;
    channel: string;
    /**
     * Title of channel for push notification.
     */
    channelTitle: string;
    /**
     * Content of twitter post. Can contain HTML prefer to use summary if possible
     */
    content: any;
    /**
     * Summary of the twitter post. Does not contain HTML. Cuts off after certain amount of characters.
     */
    summary: string;
    images: DigestImage[];
  }

  type DigestFeed = Array<ITwitter | IFacebook | IBlog | IPush | ICalendar>;

  const [digest, setDigest] = useState<DigestFeed>();

  useEffect(() => {
    async function fetchMyAPI() {
      let response = await fetch(viewData?.url, {
        mode: "cors",
      });
      setDigest(await response.json());
    }
    fetchMyAPI();
  }, [viewData?.url]);

  return (
    <Switch>
      <Route exact path={`/`} render={() => <Redirect to={`/`} />} />
      <Route
        exact
        path={`/${route}`}
        render={() => (
          <NewsDigestList
            key={key}
            digest={digest}
            manifestData={manifestData}
            viewData={viewData}
            route={route}
          />
        )}
      />
      <Route
        path={`/${route}/:blog`}
        render={() => (
          <DetailView
            key={digest ? digest[0]?.epoch || 0 : ""}
            digest={digest}
            viewData={viewData}
            route={route}
            manifestData={manifestData}
          />
        )}
      />
    </Switch>
  );
});
