import * as S from '../Accordion/styles';
import { SubAccordionContainer } from './styles';
import { StyledLink } from '../../Links/Links';
import { AccordionProps } from '../MobileDropdown/MobileDropdown';

export const NestedAccordion = ({
  subColor,
  nestedSubColor,
  featuresList,
  item,
}: AccordionProps) => (
  <S.AccordionContainer subColor={subColor}>
    <S.AccordionHeader>{featuresList[item].navHeader}</S.AccordionHeader>
    <S.AccordionItems>
      {featuresList[item]?.dropdown?.map((dropdownItem: any, index: number) => (
        <SubAccordionContainer
          nestedSubColor={nestedSubColor}
          key={dropdownItem.columnHeader + index}
        >
          <S.AccordionHeader>{dropdownItem.columnHeader}</S.AccordionHeader>
          <S.AccordionItems>
            {dropdownItem.items?.map((item: any, index: number) => (
              <StyledLink to={item} key={featuresList[item].title + index}>
                {featuresList[item].title}
              </StyledLink>
            ))}
          </S.AccordionItems>
        </SubAccordionContainer>
      ))}
    </S.AccordionItems>
  </S.AccordionContainer>
);
