import * as S from './styles';
import { StyledLink, ExternalLink } from '../../Links/Links';
import { ListItem } from '../styles';

export const MegaMenu = ({ featuresList, item, colors }: any) => (
  <S.DropdownContainer>
    <S.DropdownMenu>
      {featuresList[item]?.dropdown?.map((dropdownItem: any, index: number) => (
        <S.DropdownColumn
          $primary={colors.primaryColor}
          $tertiary={colors.tertiaryColor}
          $length={featuresList[item].dropdown?.length}
          key={dropdownItem.columnHeader + index}
        >
          <S.ColumnHeader $primary={colors.primaryColor}>
            {dropdownItem.columnHeader}
          </S.ColumnHeader>
          <S.DropdownList
            $primary={colors.primaryColor}
            $tertiary={colors.tertiaryColor}
          >
            {dropdownItem.items?.map((item: any, index: number) => (
              <ListItem tabIndex={0} key={featuresList[item].title + index}>
                {featuresList[item]?.external === true ? (
                  <ExternalLink
                    href={featuresList[item].url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {featuresList[item].title}
                  </ExternalLink>
                ) : (
                  <StyledLink to={item}>{featuresList[item].title}</StyledLink>
                )}
              </ListItem>
            ))}
          </S.DropdownList>
        </S.DropdownColumn>
      ))}
    </S.DropdownMenu>
  </S.DropdownContainer>
);
