import React from "react";

export const DetailView = ({ member, handleBack }) => {
  return (
    <div className="flex flex-col md:h-96 overflow-y-scroll w-full p-2">
      <button
        className="w-36 p-2 border-2 rounded-full text-white"
        onClick={handleBack}
        style={{ backgroundColor: "#085b9e" }}
      >
        Back to List
      </button>
      <div className="flex flex-row">
        <div className="w-48 p-2">
          <img src={member?.images[0]} alt={`${member?.title}`} />
        </div>
        <div className="p-2">
          <p>{member?.title}</p>
          <p>{member?.subtitle}</p>
          <p>{member?.address}</p>
          <p>
            {isNaN(member?.distance) ? "" : member?.distance + " miles away"}
          </p>
          <div>
            <p dangerouslySetInnerHTML={{ __html: member?.content }} />
          </div>
        </div>
      </div>
    </div>
  );
};
