import React, { useState, useEffect } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import OCVAlertsContainer from "./OCVAlertsContainer/OCVAlertsContainer";
import OCVAlertsDetail from "./OCVAlertsDetail/OCVAlertsDetail";

export default withRouter(function OCVAlerts(props: any) {
  const [data, setData] = useState([]);
  const { route, link, anchorID, config } = props;

  useEffect(() => {
    async function fetchResponseData() {
      const response = await fetch(link, { mode: "cors" });
      const data = await response.json();
      setData(data);
    }
    fetchResponseData();
  }, [link]);

  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/" />} />
      <Route
        exact
        path={"/" + route}
        render={() => (
          <OCVAlertsContainer
            anchorID={anchorID}
            data={data}
            route={route}
            config={config}
          />
        )}
      />
      {data.length !== 0 ? (
        <Route
          path={"/" + route + "/:id"}
          render={() => (
            <OCVAlertsDetail
              data={data.length !== 0 ? data : []}
              config={config}
              route={route}
            />
          )}
        />
      ) : (
        " "
      )}
    </Switch>
  );
});
