import styled from 'styled-components';
import { ColorProps } from '../styles';
import { AccordionHeader, AccordionItems } from '../Accordion/styles';

export const SubAccordionContainer = styled.details<ColorProps>`
  & > summary::after {
    content: '';
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    transition: all 0.25s ease;
    border-bottom: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
    float: right;
  }

  &[open] > summary::after {
    content: '';
    transform: rotate(-135deg);
  }

  & > summary::-webkit-details-marker {
    display: none;
  }

  ${AccordionHeader} {
    font-size: 18px;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    display: inline-flex;
    padding: 0.5rem 0.75rem 0.5rem 1.25rem;
  }

  & > ${AccordionItems} {
    background: ${({ nestedSubColor }) => nestedSubColor};
  }
`;
