import { ButtonLink, ExternalLink, StyledLink } from '../Links/Links';
import * as S from './styles';
import { Dropdown } from './Dropdown/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';

export function DesktopNav({ manifestData }: any) {
  library.add(fab);

  const images = manifestData?.['stylesheet']['images'];
  const featuresList = manifestData?.['features'];
  const config: any = manifestData?.['views']['navbar2']['config'];

  const primaryColor = manifestData?.['stylesheet']['colors']['primary'];
  const tertiaryColor = manifestData?.['stylesheet']['colors']['tertiary'];

  const colors = {
    primaryColor,
    tertiaryColor,
  };

  if (!config) return 'Loading..';
  return (
    config && (
      <S.StyledNavbar
        $primary={colors.primaryColor}
        $tertiary={colors.tertiaryColor}
      >
        <S.NavDiv>
          <StyledLink to="/">
            <img
              src={images['sheriffPatch']['url']}
              alt={images['sheriffPatch']['altText']}
            />
          </StyledLink>
          <h1 dangerouslySetInnerHTML={{ __html: config?.title }} />
        </S.NavDiv>
        <S.Navigation>
          <S.NavList>
            {config.items?.map((item: any, index: number) => (
              <S.NavListItem
                tabIndex={0}
                $type={featuresList[item].type}
                key={item + index}
                $primary={colors.primaryColor}
              >
                {featuresList[item].type === 'submenu' ||
                featuresList[item].type === 'megaMenu' ? (
                  <Dropdown
                    featuresList={featuresList}
                    item={item}
                    index={index}
                    colors={colors}
                  />
                ) : featuresList[item]?.external === true ? (
                  <ExternalLink
                    href={featuresList[item].url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {featuresList[item].title}
                  </ExternalLink>
                ) : (
                  <StyledLink to={item}>{featuresList[item].title}</StyledLink>
                )}
              </S.NavListItem>
            ))}
          </S.NavList>
        </S.Navigation>
        <S.NavDiv>
          {config?.socialMedia?.map(
            (item: { icon: IconName; url: string }, index: number) => (
              <ExternalLink
                href={item.url}
                key={item.icon + index}
                target="_blank"
                aria-label={`Link to ${item.url}`}
              >
                <FontAwesomeIcon icon={['fab', item.icon]} size="lg" />
              </ExternalLink>
            )
          )}
          <ButtonLink to={config.feature} $primary={colors.primaryColor} button>
            View Our App
          </ButtonLink>
        </S.NavDiv>
      </S.StyledNavbar>
    )
  );
}
