import React from "react";
import { SearchBar } from "../../OCVMap/OCVMapComponents/Search";

export const MapMembers = ({
  members,
  handleClick,
  searchName,
  setSearchName,
  searchAddress,
  setSearchAddress,
  filterLocation,
  isActive,
  setIsActive,
}) => {
  return members ? (
    <div className="flex flex-col w-full h-96 p-2">
      <div className="flex flex-row p-2 max-h-16 rounded-lg">
        <SearchBar
          value={isActive.active === "name" ? searchName : searchAddress}
          placeholder={`Search by ${isActive.active}...`}
          onChange={
            isActive.active === "name" ? setSearchName : setSearchAddress
          }
        />
        <div className="pl-2">
          <div
            className="w-auto flex flex-row border-2 rounded-lg transition"
            style={{ borderColor: "#085b9e" }}
          >
            <button
              className={`p-2`}
              style={
                isActive.active === "name"
                  ? { backgroundColor: "#085b9e", color: "white" }
                  : {}
              }
              onClick={() =>
                setIsActive({
                  active: "name",
                })
              }
            >
              Name
            </button>
            <button
              className={`p-2 `}
              style={
                isActive.active === "address"
                  ? { backgroundColor: "#085b9e", color: "white" }
                  : {}
              }
              onClick={() =>
                setIsActive({
                  active: "address",
                })
              }
            >
              Address
            </button>
          </div>
        </div>
      </div>
      <div className="h-96 overflow-y-scroll w-full p-2">
        {filterLocation(members, searchName, searchAddress).map((member) => (
          <button
            onClick={() => handleClick(member)}
            key={member?.title}
            className="border-2 p-2 flex flex-row w-full"
          >
            <div className="w-24 p-2">
              <img src={member?.images[0]} alt={`${member?.title}`} />
            </div>
            <div className="p-2 w-full">
              <p>{member?.title}</p>
              <p>{member?.subtitle}</p>
              <p>{member?.address}</p>
              <p>
                {isNaN(member?.distance)
                  ? ""
                  : member?.distance + " miles away"}
              </p>
            </div>
          </button>
        ))}
      </div>
    </div>
  ) : (
    ""
  );
};
