import { Accordion } from '../Accordion/Accordion';
import { NestedAccordion } from '../NestedAccordion/NestedAccordion';

export interface AccordionProps {
  subColor: string;
  nestedSubColor?: string;
  featuresList: { [key: string]: any };
  item: string;
}

export const MobileDropdown = ({
  featuresList,
  item,
  subColor,
  nestedSubColor,
}: AccordionProps) => {
  if (featuresList[item].type === 'submenu') {
    return (
      <Accordion featuresList={featuresList} item={item} subColor={subColor} />
    );
  } else {
    return (
      <NestedAccordion
        featuresList={featuresList}
        item={item}
        subColor={subColor}
        nestedSubColor={nestedSubColor}
      />
    );
  }
};
