import React from "react";

import ios from "../../../assets/app-store-badge.svg";
import google from "../../../assets/Google_Play-Badge-Logo.wine.svg";
import { HeaderBorder } from "../../OCVFeatures/PossibilityBox/PossibilityBox";

import * as S from "./styles";

export default function DownloadOurApp(props: any) {
  const config = props.viewData?.config;
  let backgroundImageConfig = undefined;

  const appID = process.env.REACT_APP_APPID;

  if (config?.rgba1 && config?.rgba2 && config?.backgroundImageURL)
    backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2}), url(${config.backgroundImageURL})`;
  else if (config?.rgba1 && config?.rgba2)
    backgroundImageConfig = `linear-gradient(${config.rgba1}, ${config.rgba2})`;
  else if (config?.backgroundImageURL)
    backgroundImageConfig = `url(${config.backgroundImageURL})`;

  return (
    <S.OuterWrapper
      as='section'
      bgColor={config?.bgColor}
      textColor={config?.textColor}
    >
      <S.InnerWrapper as='div'>
        <S.HeaderWrapper as='div'>
          <S.HeadingText as='h2' fontFam={config?.font}>
            {props.viewData?.title}
          </S.HeadingText>
          <HeaderBorder $divideColor={config?.divideColor} />
        </S.HeaderWrapper>
        <S.GridContainer as='div'>
          <S.FlexContainer as='div'>
            <S.ImgMockup
              as='img'
              src={props.viewData?.mockup}
              alt={props?.viewData?.config?.alt}
            />
          </S.FlexContainer>
          <S.Flex as='div'>
            <S.TextDesc as='p'>{config?.description}</S.TextDesc>
            <S.LinkButton buttonColor={config?.buttonColor}  
            buttonText={config?.buttonText} 
            to={"/" + props.viewData.config?.feature}
             >
              View More Info
            </S.LinkButton>
            <S.GridContainer as='div'>
              <S.FlexContainer as='div'>
                <a
                  href={config?.iosLink}
                  rel='noopener noreferrer'
                  target={"_blank"}
                >
                  <S.ImgDownloadApp
                    as='img'
                    alt='Click to view our app in the apple store'
                    src={ios}
                  />
                </a>
                <a
                  href={props.viewData.config?.androidLink}
                  rel='noopener noreferrer'
                  target={"_blank"}
                >
                  <S.ImgDownloadApp
                    as='img'
                    alt='Click to view our app in the google play store'
                    src={google}
                  />
                </a>
              </S.FlexContainer>
              <S.FlexContainer as='div' data-hidden='true'>
                <S.ImgQRCode
                  as='img'
                  alt='QR Code Download App Now'
                  src={`https://s3.amazonaws.com/myocv/ocvapps/${appID}/qrcode/${appID}.png`}
                />
                <S.CaptionQRCode as='p'>DOWNLOAD NOW</S.CaptionQRCode>
              </S.FlexContainer>
            </S.GridContainer>
          </S.Flex>
        </S.GridContainer>
      </S.InnerWrapper>
    </S.OuterWrapper>
  );
}
