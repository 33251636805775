import React from 'react'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps'
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer'

// React-Google-Maps Documentation
// https://tomchentw.github.io/react-google-maps/#documentation

export const MapView = withScriptjs(
  withGoogleMap(
    ({
      isMarkerShown,
      locations,
      filterLocation,
      handleClick,
      zoom,
      searchName,
      searchAddress,
      clusterSize,
    }) => {
      return locations ? (
        <GoogleMap
          defaultOptions={{
            scrollwheel: true,
            gestureHandling: 'auto',
          }}
          center={{
            lat: Number(locations?.data?.points[0]?.latitude),
            lng: Number(locations?.data?.points[0]?.longitude),
          }}
          defaultZoom={9}
          zoom={zoom ? zoom : 9}
        >
          <MarkerClusterer
            zoomOnClick={true}
            minimumClusterSize={clusterSize ? clusterSize : 5}
          >
            {filterLocation(locations, searchName, searchAddress)?.map(
              (location) =>
                isMarkerShown && (
                  <Marker
                    onClick={() => handleClick(location)}
                    key={location?.pinID}
                    position={{
                      lat: Number(location?.latitude),
                      lng: Number(location?.longitude),
                    }}
                  />
                ),
            )}
          </MarkerClusterer>
        </GoogleMap>
      ) : (
        ''
      )
    },
  ),
)
