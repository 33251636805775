import React from 'react'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps'
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer'

// React-Google-Maps Documentation
// https://tomchentw.github.io/react-google-maps/#documentation

export const MapView = withScriptjs(
  withGoogleMap(
    ({
      isMarkerShown,
      locations,
      filterLocation,
      handleClick,
      center,
      zoom,
      searchName,
      searchAddress,
      clusterSize,
    }) => {
      return locations ? (
        <GoogleMap
          // this is specifically for when a marker/person is clicked
          // it changes the center to that marker
          defaultCenter={{
            lat: locations?.data?.county?.latitude,
            lng: locations?.data?.county?.longitude,
          }}
          defaultOptions={{
            scrollwheel: true,
            gestureHandling: 'auto',
          }}
          defaultZoom={9}
          center={
            center
              ? { lat: center?.lat, lng: center?.lng }
              : {
                  lat: locations?.data?.county?.latitude,
                  lng: locations?.data?.county?.longitude,
                }
          }
          zoom={zoom ? zoom : 9}
        >
          <MarkerClusterer
            zoomOnClick={true}
            minimumClusterSize={clusterSize ? clusterSize : 5}
          >
            {filterLocation(locations, searchName, searchAddress)?.map(
              (location) =>
                isMarkerShown && (
                  <Marker
                    onClick={() => handleClick(location)}
                    key={location?.title}
                    position={{
                      lat: location?.latitude,
                      lng: location?.longitude,
                    }}
                  />
                ),
            )}
          </MarkerClusterer>
        </GoogleMap>
      ) : (
        ''
      )
    },
  ),
)
