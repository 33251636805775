import React from 'react'
import { SearchBar } from './Search'
import defaultImage from '../../../../assets/default_image.jpeg'

export const MapMembers = ({
  members,
  handleClick,
  searchName,
  setSearchName,
  searchAddress,
  setSearchAddress,
  filterLocation,
  isActive,
  setIsActive,
}) => {
  return members ? (
    <div className="flex flex-col w-full h-96 p-2">
      <div className="flex flex-row p-2 max-h-16 rounded-lg">
        <SearchBar
          value={isActive.active === 'name' ? searchName : searchAddress}
          placeholder={`Search by ${isActive.active}...`}
          onChange={
            isActive.active === 'name' ? setSearchName : setSearchAddress
          }
        />
        <div className="pl-2">
          <div
            className="w-auto flex flex-row border-2 rounded-lg transition"
            style={{ borderColor: '#085b9e' }}
          >
            <button
              className={`p-2`}
              style={
                isActive.active === 'name'
                  ? { backgroundColor: '#085b9e', color: 'white' }
                  : {}
              }
              onClick={() =>
                setIsActive({
                  active: 'name',
                })
              }
            >
              Name
            </button>
            <button
              className={`p-2 `}
              style={
                isActive.active === 'address'
                  ? { backgroundColor: '#085b9e', color: 'white' }
                  : {}
              }
              onClick={() =>
                setIsActive({
                  active: 'address',
                })
              }
            >
              Address
            </button>
          </div>
        </div>
      </div>
      <div className="h-96 overflow-y-scroll w-full ">
        {filterLocation(members, searchName, searchAddress).map((member) => (
          <button
            onClick={() => handleClick(member)}
            key={member?.pinID}
            className="border-2 p-2 flex flex-row w-full"
          >
            <div className="w-24 p-2">
              <img
                src={
                  member?.images?.length === 0
                    ? defaultImage
                    : member?.images[0]
                }
                alt={`${member?.title}`}
              />
            </div>
            <div className="p-1 w-full">
              <p>{member?.title}</p>
              {member?.subtitle ? (
                <p className="p-1">{member?.subtitle}</p>
              ) : null}
              <p className="p-1">{member?.address}</p>
              {member?.phone?.map((item) =>
                item.name.toLowerCase() === 'phone' ? (
                  <a
                    className="p-1"
                    key={item.phone}
                    href={`tel:${item.phone}`}
                  >
                    {item.phone}
                  </a>
                ) : null,
              )}
              <p className="p-1">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`http://maps.google.com/?q=${member?.address}`}
                >
                  Get Directions
                </a>
                {isNaN(member?.distance)
                  ? ''
                  : ` - ${member?.distance} miles away`}
              </p>
            </div>
          </button>
        ))}
      </div>
    </div>
  ) : (
    ''
  )
}
