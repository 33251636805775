import {createStyles} from '@material-ui/core';

const sliderStyle = createStyles({
    slider: {
        position: "relative",
        height: "100%",
        width: "auto"
    },
    innerContainer: {
        width: '100%',
        height: '100%',
        margin: '0',
        position: 'relative'
    },
    slide: {
        textAlign: 'center',
        width: '100%',
        background: '#000',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    images: {
        objectFit: "cover",
        width:"100%",
        height: "550px",
        '@media (max-width: 600px)': {
            height: "350px"
        }
    }
});

export default sliderStyle;