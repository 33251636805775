import React from "react";
import { Link } from "react-router-dom";

export default function OCVBlogGridWidget(props: any) {
  const { viewData, manifestData } = props;

  const [blogData, setBlogData] = React.useState<any>([]);

  const blogGridPage = manifestData["features"][viewData?.config?.feature];
  React.useEffect(() => {
    fetch(blogGridPage?.url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => setBlogData(data));
  }, [blogGridPage]);

  const config = viewData?.config;
  return (
    <div
      className={`py-4 bg-cover space-y-4 ${config?.additionalTailwindStyles}`}
      style={{
        backgroundColor: viewData?.config?.bgColor,
      }}
    >
      <h1
        style={{
          color: viewData.config.titleColor
            ? viewData.config.titleColor
            : "black",
        }}
        className="uppercase text-2xl flex flex-col items-center tracking-tight font-extrabold text-gray-900 sm:text-3xl md:text-4xl"
      >
        {blogGridPage?.title}
        <hr
          className="mt-3 mb-2 border-b-4 w-1/6 p-0.5"
          style={{
            background: viewData?.config?.hrColor,
          }}
        />
      </h1>

      <div
        className={`w-10/12 mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-${
          blogGridPage?.config?.numCols * 2
        } pt-4`}
      >
        {blogData.splice(0, blogGridPage?.config?.numCols).map((blog: any) => (
          <div className="col-span-1 lg:col-span-2 mx-2 my-3" key={blog?.title}>
            <div className="pb-4">
              <img
                alt={blog?.title}
                src={blog?.images[0]?.large}
                className={`w-auto max-h-96 object-cover mx-auto ${
                  viewData?.config?.rounded ? "rounded-3xl" : null
                }`}
              />
            </div>
            {!viewData?.config?.showTitle && (
              <div
                style={{ color: viewData.config.titleColor }}
                className={`w-full mx-auto text-lg font-semibold lg:text-xl text-center pb-2`}
              >
                {`${blog?.title}`}
              </div>
            )}
            {blog?.extra && (
              <div className="flex flex-row justify-center space-x-2">
                {blog?.extra?.length === 1 ? (
                  <a
                    href={blog?.extra[0]?.url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <button
                      className={`border-2 rounded-full font-medium border-${viewData.config.buttonBorderColor} text-${viewData.config.buttonTextColor} p-2`}
                    >
                      {blog?.extra[0]?.title}
                    </button>
                  </a>
                ) : (
                  blog?.extra?.map((links: any) => (
                    <a
                      href={links?.url}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <button
                        className={`mx-auto rounded-full border-2 font-medium border-${viewData.config.buttonBorderColor} text-${viewData.config.buttonTextColor} p-2`}
                      >
                        {links?.title}
                      </button>
                    </a>
                  ))
                )}
              </div>
            )}
          </div>
        ))}
      </div>
      {viewData?.config?.viewAll && (
        <Link
          to={`/${viewData?.config?.feature.replace(/\s+/g, "")}`}
          className={`hover:text-current hover:no-underline flex justify-center items-center w-1/2 mx-auto`}
        >
          <button
            className={`text-${viewData?.config?.buttonTextColor} border-${viewData?.config?.buttonBorderColor} rounded-full hover:no-underline h-full px-8 py-2 border-2 mx-auto text-sm uppercase xl:text-lg font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          >
            View All Posts
          </button>
        </Link>
      )}
    </div>
  );
}
