import React, { useState } from 'react'
import { Row, Col } from 'reactstrap'
import Paper from '@material-ui/core/Paper/Paper'
import Lightbox from 'react-images'
import './OCVContactsEntry.css'
//import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function OCVContactsEntry(props: any) {
  const [lightboxIsOpen, setLightbox] = useState(false)

  const openLightbox = (
    event: { preventDefault: () => void },
    image: string,
  ) => {
    event.preventDefault()
    setLightbox(true)
  }
  const closeLightbox = () => {
    setLightbox(false)
  }

  const { classes, entry } = props
  return (
    <Paper className="OCVContactsEntryPaper">
      <Row className={classes.contactsEntry}>
        {'image' in entry ? (
          <Col
            className={classes.contactsImageSection + ' flex items-center'}
            xs={12}
            sm={12}
            md={2}
          >
            <div className={classes.contactsImageContainer}>
              <img
                alt={entry.image}
                onClick={(e) => openLightbox(e, entry.image)}
                className={'img-fluid'}
                width={300}
                height={300}
                src={entry.image === null ? '' : entry.image}
              />
            </div>
            {/* <div className={classes.contactsSocialContainer}>
                        <ul className={classes.contactsSocialList}>
                            <li className={classes.contactsSocialListItem}>
                                <a className={classes.contactsSocialLink}>
                                    <FontAwesomeIcon size={'lg'} icon={['fab', 'facebook']} />
                                </a>
                            </li>
                            <li className={classes.contactsSocialListItem}>
                                <a className={classes.contactsSocialLink}>
                                    <FontAwesomeIcon size={'lg'} icon={['fab', 'linkedin']} />
                                </a>
                            </li>
                            <li className={classes.contactsSocialListItem}>
                                <a className={classes.contactsSocialLink}>
                                    <FontAwesomeIcon size={'lg'} icon={['fab', 'instagram']} />
                                </a>
                            </li>
                            <li className={classes.contactsSocialListItem}>
                                <a className={classes.contactsSocialLink}>
                                    <FontAwesomeIcon size={'lg'} icon={['fab', 'twitter']} />
                                </a>
                            </li>
                        </ul>
                    </div>*/}
          </Col>
        ) : (
          ''
        )}
        <Col className={classes.contactsTextSection} xs={12} sm={12} md={8}>
          <h3 className="p-1 text-2xl font-semibold">{entry.title}</h3>
          {'jobtitle' in entry ? (
            <p className="p-1 text-xl break-words">{entry.jobtitle}</p>
          ) : (
            ''
          )}
          <p className={'p-1 text-lg break-words'}>
            {'address' in entry ? (
              <a
                className="OCVContactsEntryAddress pb-1"
                target="_blank"
                rel="noopener noreferrer"
                href={'http://maps.google.com/?q=' + entry.address}
              >
                {entry.address}
              </a>
            ) : (
              ''
            )}
            {'email' in entry ? (
              <span className="text-lg break-words">
                {' '}
                Email: <a href={'mailto:' + entry.email}>{entry.email}</a>
                <br />
              </span>
            ) : (
              ''
            )}
            {'phone' in entry ? (
              <span className="text-lg break-words">
                {' '}
                Phone: <a href={'tel:' + entry.phone}>{entry.phone}</a>
                <br />
              </span>
            ) : (
              ''
            )}
            {'fax' in entry ? (
              <span className="text-lg break-words">
                Fax: <a href={'tel:' + entry.fax}>{entry.fax}</a>
                <br />
              </span>
            ) : (
              ''
            )}
            {'website' in entry ? (
              <span className="text-lg break-words">
                <a
                  href={
                    !entry.website.includes('http')
                      ? 'https://' + entry.website
                      : entry.website
                  }
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Visit Website
                </a>
              </span>
            ) : (
              ''
            )}
          </p>
          {'description' in entry ? (
            <p className={'p-1 text-lg break-words'}>{entry.description}</p>
          ) : (
            ''
          )}
        </Col>
      </Row>
      <Lightbox
        backdropClosesModal
        onClose={closeLightbox}
        isOpen={lightboxIsOpen}
        images={[{ src: entry.image }]}
      />
    </Paper>
  )
}
