import { MobileNav } from '../MobileNav/MobileNav';
import { DesktopNav } from '../DesktopNav/DesktopNav';

export const Navbar2 = ({ manifestData }: any) => (
  <>
    <DesktopNav key={'DesktopNav'} manifestData={manifestData} />
    <MobileNav key={'MobileNav'} manifestData={manifestData} />
    <div
      key={'DummyHeader'}
      id="dummy-header"
      style={{ marginTop: '100px' }}
    ></div>
  </>
);
