import React from "react";

export default function BlogListWidgetPagination(props) {
  let { totalEntries, limit, pageRight, pageLeft, page, setPage } = props;
  let totalPages;

  if (totalEntries % limit === 0) {
    totalPages = totalEntries / limit;
  } else {
    totalPages = Math.ceil(totalEntries / limit);
  }

  function handleClickRight(e) {
    if (page !== totalPages) {
      setPage(page + 1);
      pageRight();
    }
  }

  function handleClickLeft(e) {
    if (page !== 1) {
      setPage(page - 1);
    }
  }

  return (
    <div className="bg-white py-3 flex items-center border-gray-200 mt-8 justify-items-center">
      <div className="flex-1 justify-between hidden" />
      <div className="flex-1 flex sm:items-center justify-center">
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <button
              aria-label="Page Right"
              className={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 ${
                page === 1 ? "cursor-not-allowed bg-gray-100" : ""
              }`}
              onClick={() => {
                handleClickLeft();
                pageLeft();
              }}
              disabled={page === 1}
            >
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            <button
              aria-label="Page Left"
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 pointer-events-none"
            >
              Page {page} of {totalPages}
            </button>
            <button
              aria-label="Page right"
              className={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 ${
                page === totalPages ? "cursor-not-allowed bg-gray-100" : ""
              }`}
              onClick={() => {
                handleClickRight();
              }}
              disabled={page === totalPages}
            >
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}
