import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagramSquare,
  IconDefinition,
  faFacebookSquare,
  faTwitterSquare,
  faAppStoreIos,
  faApple,
  faGooglePlay,
  faYoutubeSquare,
  faAndroid,
} from "@fortawesome/free-brands-svg-icons";

// "props" needs to be an object containing properties url, title, and logoColor
export default function SocialIcons({
  props,
  divClasses = "",
  linkClasses = "",
}: any) {
  // these nested objects will hold the images for all future logos
  // the index signature ({ [k: string]: { [key: string]: string } })
  // lets the compiler know which types we're accepting
  // for both our keys and values when referencing our logoObject
  // this must be explicitly typed since we're using indexed access types below
  const logoObject: { [key: string]: { [key: string]: IconDefinition } } = {
    instagram: {
      icon: faInstagramSquare,
    },

    facebook: {
      icon: faFacebookSquare,
    },

    twitter: {
      icon: faTwitterSquare,
    },

    youtube: {
      icon: faYoutubeSquare,
    },

    ios: {
      icon: faAppStoreIos,
    },

    apple: {
      icon: faApple,
    },

    google: {
      icon: faGooglePlay,
    },

    android: {
      icon: faAndroid,
    },
  };

  // prop destructuring
  const { url, title, logoColor } = props;

  return (
    <div key={title} className={divClasses}>
      <a
        href={url}
        rel='noreferrer noopener'
        target='_blank'
        aria-label={title}
        className={linkClasses}
      >
        <FontAwesomeIcon
          size='lg'
          icon={logoObject[title?.toLowerCase()].icon}
          color={logoColor}
        />
      </a>
    </div>
  );
}
