import styled from "styled-components";

export interface ColorProps {
  menuColor?: string;
  subColor?: string;
  nestedSubColor?: string;
}

export const Nav = styled.div<{ primary: string; tertiary: string }>`
  height: 100px;
  width: 100%;
  background-color: ${({ primary }) => primary};
  border-bottom: 4px solid ${({ tertiary }) => tertiary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  padding: 0 0.5rem;
  position: fixed;
  top: 0;

  & > a {
    padding: 0;
  }

  @media screen and (min-width: 1150px) {
    display: none;
  }
`;

export const MenuButton = styled.label`
  position: fixed;
  top: 50px;
  right: 10px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 1;

  & > span,
  & > span::before,
  & > span::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #fff;
    transition-duration: 0.25s;
  }

  & > span::before {
    content: "";
    top: -8px;
  }

  & > span::after {
    content: "";
    top: 8px;
  }
`;

export const Main = styled.main<ColorProps>`
  width: 100%;
  position: fixed;
  top: 100px;
  left: -100%;
  bottom: 10vh;
  background: ${({ menuColor }) => menuColor};
  overflow-y: scroll;
  overflow-x: hidden;
  transition: 0.25s;
  color: #f0f0f0;

  & > *:not(:last-child) {
    border-bottom: 1px solid rgb(255, 255, 255, 0.4);
  }
`;

export const Socials = styled.div<{ primary: string; tertiary: string }>`
  height: 10vh;
  width: 100%;
  border-top: 1px solid ${({ tertiary }) => tertiary};
  background-color: ${({ tertiary }) => tertiary};
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0.5rem 0;
  position: fixed;
  bottom: 0;
  left: -100%;
  transition: 0.25s;
  color: #f0f0f0;
`;

export const MenuToggle = styled.input`
  opacity: 0;

  &:checked + ${MenuButton} {
    ${Main}, ${Socials} {
      width: 100%;
      left: 0;
    }
    & > span {
      transform: rotate(45deg);

      &::before {
        top: 0;
        transform: rotate(0deg);
      }

      &::after {
        top: 0;
        transform: rotate(90deg);
      }
    }
  }
`;
