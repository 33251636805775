import React from "react";
import { TwitterTimelineEmbed } from "react-twitter-embed";

export default function TwitterWidget(props: any) {
  const { config } = props;
  return (
    <TwitterTimelineEmbed
      id={props.anchorID}
      sourceType="profile"
      screenName={config["twitterHandle"]}
      options={{ height: config["height"] }}
    />
  );
}
